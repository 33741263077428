/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
@import '../../abstracts/import-abstracts';
// % protected region % [Add additional imports here] off begin
// % protected region % [Add additional imports here] end

// % protected region % [Modify form list styling here] off begin
.form-list {
    list-style: none;
    
    .form-list-item {
        list-style: none;
        list-style-position: outside;
    }
    
    .form-list-item::before {
        content: "\2022";
        color: $color-primary;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1rem;
    }
}
// % protected region % [Modify form list styling here] end

/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Layout
-------------------------------- */
/* 
_layout.scss is the set up of your project on where things sit.
For example below, grid-template-areas are set in a way to position your navigation depending on 
the type of navigation you choose to use. If you want things positioning differently, change the grid-template-areas
*/

/* full body layout */
/* -------------------------------------------------*/
/* ----------Set your project layout here ----------*/
/* -------------------------------------------------*/


// % protected region % [Change project layout here] on begin
.body-container {
	display: grid;
	grid-template-areas: "adminbar"
		"contentwrapper";
	grid-template-rows: auto 1fr;
	height: 100vh;

	.frontend{
		overflow: hidden;
		display: grid;
		width: 100%;
		grid-template-areas: "navhorizontal navhorizontal"
			"navvertical body";
		grid-template-columns: minmax(0, auto) 1fr;
		grid-template-rows: auto 1fr;
		grid-area: contentwrapper;

		//Mobile responsive
		@include media(xs, sm, md) {
			grid-template-areas: "navhorizontal navhorizontal"
				"navvertical body";
			grid-template-columns: minmax(0, auto) 1fr;
			position: relative;
		}

		.body-content {
			background-color: $color-secondary;
		}
	}
}

/*
This class is where your information is in, sits next to the nav-vertical or below the nav-horizontal.
Change if require a new layout for your body content or if you have added a new custom component
*/
.body-content {
	overflow-y: auto;
	overflow-x: hidden;
	grid-area: body;
	grid-template-areas: "header"
		"content"
		"actionbar";
	grid-template-rows: auto auto 90px;
}

.crud-component,
.collection-component,
.form-submission {
	padding: 0 $space-md;
}

// % protected region % [Change project layout here] end

// % protected region % [Add additional colours here] off begin
// % protected region % [Add additional colours here] end
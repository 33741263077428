/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Navigation
-------------------------------- */
/*
Styling for basic navigation, vertical navigation and horizontal navigation.
*/

/****** General navigation styling shared amongst all navigation *****/
// % protected region % [Change generic navigation styles here] on begin
.nav {
	background-color: $color-primary;
	display: flex;
	position: relative;
	z-index: 9;
	box-shadow: 0px 15px 10px -15px #111;

	//Contains the navigation logo and the responsive menu hamburger icon//
	&__menu-controls{

		@include media(md){
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
	}

	//Contains the navigation links and profile links area within the navigation bar//
	&__menu-items {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		max-height: 80px;

		@include media(md) {
			display: none;
		}
	}

	ul {
		margin-bottom: 0;

		li {
			display: flex;
			flex-direction: column;
			list-style: none;
			position: relative;

			//This is for sublinks
			&.nav__parent-link--active {
				a {
					color: $white;
				}
			}

			a {
				color: rgba($color-primary, 0.7);
				text-decoration: none;
				padding: $space-xxs;
				display: block;
				cursor: pointer;
				transition: none;
				border-bottom: none;
			}

			span {
				white-space: nowrap;
			}

			&:hover,
			&:focus,
			&:active,
			&.active {
				@include transform-timing($animation-time);

				a {
					color: $white;
					@include transform-timing($animation-time);
				}

				//This is for sublinks
				ul li a {
					color: $white;
					transition: none;
				}
			}

			@import 'sublinks-navigation.scss';
		}
	}
}

//This is the hamburger menu which appears on Medium to Small breakpoints and is used to toggle the display of the menu//
.responsive-menu {
	display: none;

	@include media(md) {
		display: block;
		cursor: pointer;
	}

	img{
		height: 3rem;
		width: auto;
	}
}

.responsive-navigation {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	text-align: right;
}

// % protected region % [Change generic navigation styles here] end

/****** Horizontal navigation (usually placed left) *****/
// % protected region % [Change vertical navigation styles here] off begin
.nav--vertical {
	background-color: $color-brand-03;
	overflow: auto;
	flex-direction: column;
	left: 0;
	grid-area: navvertical;
	height: 100%;
	box-shadow: convert-rem(-2px) convert-rem(4px) convert-rem(7px) convert-rem(-1px);

	>ul {
		margin: 0;
		padding: 0;

		>li {
			display: block;

			&:hover,
			&:active,
			&:focus,
			&.active {
				color: $white;
			}

			//position of sublinks for vertical nav
			.nav__sublinks {
				left: 100%;
				top: 0;
				max-width: convert-rem(0px);
				width: 0;
				max-height: 70vh;
				overflow: auto;
				@include cubic-bezier-ease-in(max-width, $animation-time);

				li {
					border-bottom: convert-rem(1px) solid $white;
				}
			}

			.nav__sublinks--visible {
				max-width: convert-rem(9999px);
				width: auto;
				min-width: convert-rem(120px);

				span {
					width: 100%;
				}
			}
		}
	}

}
// % protected region % [Change vertical navigation styles here] end

/****** Horizontal navigation (usually placed top) *****/
// % protected region % [Change horizontal navigation styles here] on begin
.nav--horizontal {
	flex-direction: row;
	width: 100%;
	grid-area: navhorizontal;
	padding: 1.5rem 4rem;
	justify-content: space-between;
	align-items: center;

	.nav__menu-items {
		max-height: 2rem;

		ul li.active a {
			padding-bottom: 1.8rem;
			line-height: 14px;
		}

		ul li a {
			padding-bottom: 1.8rem;
			line-height: 14px;
		}
	}

	ul {
		display: flex;
		list-style: none;
		margin-top: auto;
		margin-bottom: auto;
		padding: 0px;

		li {
			margin-right: 3rem;

			a {
				color: $white;
				text-decoration: none;
				font-size: $text-md;
			}

			.active {
				border-bottom: 3px solid $color-brand-01;
			}

			//position of sublinks for horizontal nav
			.nav__sublinks {
				top: calc(100% + (#{2rem}*0.4));
				left: 0;
				flex-direction: column;
				max-height: 0;
				height: 0;
				@include cubic-bezier-ease-in(max-height, $animation-time);

				li {
					border-bottom: none;
					color:$white;
				}
			}

			.nav__sublinks--visible {
				max-height: convert-rem(9999px);
				height: auto;

				span {
					max-height: convert-rem(9999px);
					height: auto;
				}
			}
		}

		li.active {
			border-bottom: 3px solid $color-brand-01;
		}

		li:hover {
			border-bottom: 3px solid $white;
		}
	}
}
// % protected region % [Change horizontal navigation styles here] end

/****** Tabs *****/
// % protected region % [Change tabs styles here] off begin
.tabs {
	margin-bottom: $space-xl;
	font-size: $text-md;

	ul {
		padding-left: unset;
		display: flex;
		flex-direction: row;
		list-style: none;

		li {
			cursor: pointer;

			&.disabled {
				cursor: not-allowed;
			}

			&:not(:last-child) {
				margin-right: $space-md;
			}

			a {
				color: $color-primary;
				text-decoration: none;
			}
		}
	}

	.active {
		@include text-shadow($color-primary);

		a {
			border-bottom: convert-rem(2px) solid;
		}
	}
}
// % protected region % [Change tabs styles here] end

// % protected region % [Change tabs styles here] off begin
/****** Breadcrumbs *****/
.breadcrumbs {
	padding-left: unset;
	display: flex;
	flex-direction: row;
	list-style: none;
	align-items: center;

	li {
		&.disabled {
			cursor: not-allowed;
		}

		&:not(:last-child) {
			margin-right: $space-sm;
		}

		&:before {
			vertical-align: middle;
		}

		a {
			color: $color-primary;
			text-decoration: none;
			cursor: pointer;
		}
	}
}
// % protected region % [Change tabs styles here] end

/************ MOBILE NAVIGATION ***********/
// % protected region % [Change mobile navigation styles here] on begin
@include media(xs, sm, md) {

	//Horizontal mobile responsive
	.nav--horizontal {
		z-index: 2;
		display: flex;
		flex-direction: column;

		ul li {
			padding: $space-xs;
			font-size: $text-md;
		}

		>a {
			grid-area: collapse;
			text-align: center;
			padding: $space-xxs;
			width: 100%;
			z-index: 1;
			margin: 0;
			font-size: $text-lg;
			visibility: visible;

			&:before {
				margin-right: 0 !important;
			}

			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
				border: none;
			}
		}

		.nav__menu-controls {
			.responsive-menu {
				display: inline-block;
				cursor: pointer;
			}

			.bar1, .bar2, .bar3 {
				width: 35px;
				height: 5px;
				background-color: $color-brand-01;
				margin: 6px 0;
				transition: 0.4s;
			}

			.change .bar1 {
				-webkit-transform: rotate(-45deg) translate(-9px, 6px);
				transform: rotate(-45deg) translate(-9px, 6px);
			}

			.change .bar2 {
				opacity: 0;
			}

			.change .bar3 {
				-webkit-transform: rotate(45deg) translate(-8px, -8px);
				transform: rotate(45deg) translate(-8px, -8px);
			}
		}

		.nav__menu-items {
			max-height: 10rem;
		}

		.responsive-navigation {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			text-align: right;
		
			ul {
				display: flex;
				flex-direction: column;
		
				li {
					padding: 0px;
					margin-right: 0px;

					a {
						padding-bottom: 0px;
					}
				}

				li.active {
					padding: 0px;
					margin-right: 0px;

					a {
						padding-bottom: 0px;
					}
				}
			}
		}
	}

	.nav--horizontal.nav--expanded {
		display: grid;
		grid-template-areas: 'collapse'
			'ul';
		grid-template-rows: auto 1fr;
		grid-template-columns: 1fr;
		position: absolute;
		bottom: 0;

		ul:nth-of-type(2) {
			grid-area: ul;
			display: flex;
			flex-wrap: wrap;

			// min-height: 40vh;
			max-height: convert-rem(3000px);
			height: auto;
			overflow: hidden;
			@include heightScaleIn(2s);

			li {
				width: 50%;
				border-right: none;
				border-bottom: convert-rem(4px) solid $grey-0;

				&:nth-of-type(even) {
					border-left: convert-rem(2px) solid $grey-0;
				}

				&:nth-of-type(odd) {
					border-right: convert-rem(2px) solid $grey-0;
				}

				a {
					overflow-wrap: break-word;
					text-align: center;

					span {
						white-space: pre-wrap;
					}

					&:before {
						display: block;
						margin-bottom: $space-xs;
						font-size: $text-lg;
					}
				}
			}
		}

		ul:last-of-type {
			li {
				width: 100%;

				a {
					text-align: center;
					font-size: $text-md;

					&:before {
						margin-right: $space-sm;
					}
				}
			}
		}

		>a {
			background-color: $grey-0;
		}
	}

	.nav--horizontal.nav--collapsed {
		justify-content: center;

		ul {
			max-height: 0;
			width: 0;
			overflow: hidden;
		}

		ul:nth-of-type(2) {
			@include heightScaleIn($quick-animation-time);
		}
	}


	//Vertical mobile responsive
	.nav--vertical {
		position: absolute;
	}

	.nav--vertical.nav--expanded {
		height: 100%;
		width: auto;
		border-radius: 0;
		margin: 0;
		@include slideInLeft($animation-time);

		>ul {
			>li {
				>.nav__sublinks {
					max-height: unset;
					max-width: 0;
					height: 100vh;
					top: 0;
					min-width: 0;

					&--visible {
						width: 100%;
						max-width: 300000px;
						@include slideInLeft($animation-time);
					}
				}
			}
		}
	}

	.nav--vertical.nav--collapsed {
		@include slideOutLeft($animation-time);
		background-color: transparent;
		box-shadow: none;

		ul {
			max-height: 0;
			width: 0;
			overflow: hidden;
		}

		>a {
			display: flex;
			align-items: center;
			justify-content: center;
			height: convert-rem(55px);
			width: convert-rem(55px);
			border-radius: 50%;
			box-shadow: convert-rem(0px) convert-rem(0px) convert-rem(5px) convert-rem(-2px);
			background-color: $white;
			@include slideInLeft($quick-animation-time);
		}
	}
}
// % protected region % [Change mobile navigation styles here] end

// % protected region % [Add additional navigation styles here] off begin
// % protected region % [Add additional navigation styles here] end
/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Pagination
-------------------------------- */
/*
Found under collection list 
*/

// % protected region % [Change pagination styles here] off begin
.pagination {
	display: flex;
	justify-content: flex-end;

	ul {
		list-style: none;
		display: flex;
		align-content: center;
		align-items: center;

		li {
			.btn {
				padding: $space-xs $space-sm;
				font-size: $text-sm;

				&:hover,
				&:active {
					text-decoration: none;
					text-shadow: convert-rem(0px) convert-rem(0px) convert-rem(1px) $color-primary;
				}

				&:before,
				&:after {
					margin: 0 $space-xs;
				}
			}

			.pagination__page-number {
				margin: 0 $space-md;
				font-size: $text-sm;
			}
		}
	}
}
// % protected region % [Change pagination styles here] end

// % protected region % [Add additional paginations styles here] off begin
// % protected region % [Add additional paginations styles here] end
.user-profile {
  padding: $spacing-xl;
  display: flex;
  flex-direction: column;

  &__top,
  &__bottom {
    display: flex;
    justify-content: space-between;
  }

  &__top {
    margin-bottom: $spacing-xl;
  }

  &__content {
    padding: $spacing-md;

    h3,
    h4 {
      margin: 0;
    }

    .profile-details {
      display: flex;

      .labels {
        display: flex;
        flex-direction: column;
        margin-right: 30px;

        label {
          margin-top: 7px;
          margin-bottom: 14px;
        }
      }
  
      .values {
        display: flex;
        flex-direction: column;
        margin: none;

        p, a {
          margin-top: 7px;
          margin-bottom: 14px;
        }

        .profile-validation-alert {
          color: red;
        }
      }
    }

    .detail-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      .detail-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .detail-value {
          padding: 1rem 0rem 1rem 0rem;
          background-color: #242d37;
          color: white;
          width: 50%;

          .detail-undefined {
            color: red;
          }
        }

        .detail-expiry {
          padding: 1rem;
          background-color: #242d37;
          color: white;
          width: 30%;
          margin-right: 10%;
          padding-left: 3%;
          @include lightning-icon(before, calender);

          .detail-undefined {
            color: red;
          }

          .profile-validation-alert {
            color: red;
          }
        }
      }
    }
  }

  .input-group__dropdown {
    margin: 0;
    width: 50%;
    margin-bottom: 30px;

    .dropdown__container.single {
      margin: unset;
      background-color: #242d37;
      color: white;

      div.text {
        color: white;
      }
    }
  }

  &__details-container,
  &__inputs-container,
  &__history-container {
    background-color: $color-primary;
    border-radius: $radius;
  }

  &__details-container,
  &__inputs-container {
    width: 65%;
  }

  &__history-container,
  &__logo-container {
    width: 30%;
  }

  &__requests {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: $spacing-sm;


    h4 {
      margin: 0;
    }
  }
}

.arrow-closed, .arrow-open {
  border: solid #999;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 10px;
}

.arrow-closed {
  top: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-open {
  top: 10px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.Dropdown-root {
  position: relative;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  box-sizing: border-box;
  color: white;
  outline: none;
  transition: all 200ms ease;

  .Dropdown-placeholder {
    padding-left: 1rem;
  }
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  width: 0
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  background-color: white;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1.2);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  display: block;
  padding: 8px 10px 8px 16px;
  color: black;
}

.Dropdown-option:hover {
  background-color: #cecece;
  color: #333;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
   border-bottom-left-radius: 2px;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.user-profile__title {
  h3 {
    display: block; 
    margin: 1em 0em;
    font-size: xx-large;
  }
}

.user-profile__details-container {
  .input-group {
    margin: 0;
    background: transparent;
    border: none;

    input {
      margin: 0;
      background: none;
      border: 0;
    }
  }
}

.subheading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  h3{
    margin: 0;
  }
}

.total-number {
  background-color: $color-support-blue;
  padding: $spacing-md;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reported-requests {
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    text-decoration: none;
    transition: all 0.2s ease;
    color: $color-support-blue;
    cursor: pointer;

    &:hover {
      transform: translateY(-0.2rem);
    }
  }

  .increased {
    @include lightning-icon(before, chevron-up);
    color: $color-support-green;
  }

  .decreased {
    @include lightning-icon(before, chevron-down);
    color: $color-support-red;
  }
}

.user-profile__logo-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  img{
    width: 200px;
  }
}

.action-link {
  @include lightning-icon(after, arrow-right);
  text-decoration: none;
}

@include media(xs, sm, md) {
  .user-profile {
    &__top,
    &__bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__top {
      margin-bottom: $spacing-xl;
    }

    &__content {
      padding: $spacing-md;
      margin-bottom: 2rem;

      h3,
      h4 {
        font-size: xx-large;
        margin: 0;
      }

      .profile-details {
        display: flex;

        .labels {
          display: flex;
          flex-direction: column;
          margin-right: 30px;

          label {
            margin-top: 7px;
            margin-bottom: 14px;
          }
        }
    
        .values {
          display: flex;
          flex-direction: column;
          margin: none;

          p, a {
            margin-top: 7px;
            margin-bottom: 14px;
          }

          .profile-validation-alert {
            color: red;
          }
        }
      }

      .detail-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .detail-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .detail-value {
            padding: 1rem;
            background-color: #242d37;
            color: white;
            width: 100%;

            .detail-undefined {
              color: red;
            }
          }

          .detail-expiry {
            margin-top: 1rem;
            padding: 1rem;
            background-color: #242d37;
            color: white;
            width: 100%;
            @include lightning-icon(before, calender);

            .detail-undefined {
              color: red;
            }

            .profile-validation-alert {
              color: red;
            }
          }
        }
      }

    }

    &__details-container,
    &__inputs-container,
    &__history-container {
      background-color: #111b26;
      border-radius: $radius;

      .user-profile__content {
        background-color: $color-primary;
        display: flex;
        flex-direction: column;

        .user-profile__requests {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &__details-container,
    &__inputs-container {
      width: 100%;
    }

    &__history-container,
    &__logo-container {
      width: 100%;
    }
  }
}
.report-container,
.adjudication-form {
	.report-tile {
		display: flex;
		flex-direction: column;
		padding: $space-md;

		span {
			display: inline-block;
			color: $color-silver;
			flex: 1;

			&.bold {
				font-weight: bold;
				margin-bottom: 0.5rem;
			}

			&.italic {
				font-style: italic;
			}

			&.no-gap {
				margin: 0;
			}

			&.gap {
				margin-top: $space-md;
				margin-bottom: $space-sm;
			}

			&.medium {
				font-size: 1.2rem;
			}

			&.large {
				font-size: 1.75rem;
			}
		}

		p {
			color: $color-silver;
		}

		.btn {
			margin: 0;
			min-height: 36px;
			max-height: 36px;
			min-width: 50px;
			line-height: 0.5;
			text-align: center;
			border: 1px solid $color-silver;
			border-radius: 5px;
			background: none;
			color: $color-silver;
			font-weight: bold;

			&:hover {
				color: $color-support-green;
				border: 1px solid $color-support-green;
			}

			&.active {
				color: $grey-8;
				background: $color-support-green;
				border: 1px solid $color-support-green;
			}

			&.inactive {
				color: $color-silver;
				border: 1px solid $grey-5;
				cursor: default;
			}
		}

		.space {
			margin-top: $space-sm;
		}

		.flex {
			display: flex;

			& > * {
				margin: 0.5rem;
			}
		}

		.report-section {
			h5 {
				&.no-gap {
					margin-bottom: 0;
				}
			}

			.report-subsection {
				&.flex {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					align-items: flex-start;

					& > * {
						margin-bottom: $space-md;
					}

					&.ignore-cols {
						padding: 0.5rem;

						& > div {
							margin: 0 1rem 0.5rem 1rem;
						}

						.report-subsection-element {
							flex-basis: auto;
						}
					}
				}

				&.cols {
					-moz-columns: 4 250px;
					columns: 4 250px;

					.report-subsection-element {
						-moz-column-break-inside: avoid;
						break-inside: avoid;
						overflow: hidden;
					}
				}

				@media screen and (max-width: 1200px) {
					flex-direction: column;
				}

				.report-subsection-element {
					display: flex;
					flex-direction: column;
					justify-content: center;
					flex: 0 0 40%;

					.title {
						text-align: center;
						margin-bottom: 0;
					}
				}

				.other-abnormalities-col-inner {
					margin-bottom: $space-xs;
					display: flex;
					flex-direction: row;
					align-items: center;

					p {
						padding-left: 6px;
						margin: 0;
					}
				}
			}
		}
	}

	.button-group {
		display: flex;
		flex-wrap: wrap;
		padding: 0.5rem;

		.btn {
			margin: 0 1rem 0.75rem 0;
		}
	}

	.button-grid {
		display: grid;
		row-gap: $space-xs;
		-moz-column-gap: $space-xs;
		column-gap: $space-xs;
		justify-items: center;
		align-items: center;

		.align-right {
			justify-self: end;
			text-align: right;
		}

		&.grid-col-2 {
			grid-template-columns: repeat(2, 60px);
		}

		&.grid-col-3 {
			grid-template-columns: repeat(3, 60px);

			.title {
				grid-column: 2 / 4;
			}

			.header {
				align-self: end;
			}
		}

		&.grid-col-3-long {
			grid-template-columns: 100px repeat(2, 60px);
		}

		&.grid-col-4 {
			grid-template-columns: repeat(4, 60px);
		}

		&.grid-col-5 {
			grid-template-columns: 40px repeat(4, 60px);

			.header {
				grid-column: 2 / span 4;
			}
		}
	}

	.button-list {
		display: flex;
		flex-direction: column;

		.button-row {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			margin-bottom: 0.5rem;

			p {
				margin: 0;
				padding-left: $space-xs;
			}
		}
	}

	.grade-matrix-container {
		display: flex;

		& > div {
			&:not(:first-of-type) {
				margin-left: $space-sm;
			}
		}

		.grade-sum {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			span {
				flex: 0 1;
			}
		}

		.matrix-button {
			width: 100%;
		}
	}

	.comment-box {
		display: flex;
		flex-direction: column;

		span {
			margin-top: $space-md;
			margin-bottom: $space-sm;
		}

		input {
			background: $color-primary;
			padding: $space-sm;
		}

		textarea {
			background: $color-primary;
			padding: $space-xs;
			resize: none;
			width: 100%;
			white-space: pre-wrap;
		}
	}
}

.report-footer {
	position: fixed;
	flex: 1;
	bottom: 0;
	height: 80px;
	max-height: 80px;
	width: 100%;
	background: $grey-8;

	display: flex;
	align-items: center;
	padding: 0 $space-xl;

	button {
		padding-right: $space-lg;
		padding-left: $space-lg;

		&:not(:first-of-type) {
			margin-left: $space-md;
		}
	}

	.custom-spinner {
		margin-left: $space-md;
		padding: 0 5.4rem;
		.custom-loader {
			margin-top: 0;
		}
	}

	.primary-btns {
		display: flex;
		margin-left: auto;

		align-items: center;
	}
}
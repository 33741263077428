.adjudication-form {
  display: flex;
  // 100% - bottom bar - navbar
  height: calc(100vh - 80px - 70px);
  flex-direction: column;
  flex: 1;
  min-width: 33%;
  background: $color-primary;
  overflow-y: auto;
  
  .accordion__item {
    background: $color-primary;
    
    &:last-of-type {
      .accordion__panel {
        margin-bottom: 80px;
      }
    }
  }
  
  &.read-only {
    background: $color-secondary;
    min-width: 45%;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    
    .accordion__heading {
      background: $color-secondary;
    }

    .accordion__item {
      background:  $color-secondary;
    }
  }
  
  .report-details {
    padding: $space-lg;
    color: $color-silver;
    height: 150px;
    min-height: 150px;
  }
}

.adjudication-scrollable {
  flex: 2;
  // 100% - bottom bar - navbar
  height: calc(100vh - 80px - 70px);
  display: flex;
  overflow-y: auto;
}

.adjudication-panel {
  display: flex;
  
  .accordion {
    flex: 1;
    border-bottom: none;
  }
}

.accordion__heading {
  color: $color-silver;
  background: $grey-6;
  border: 1px solid $grey-1;
  border-top: none;
  
  .accordion__button {
    display: flex;
    padding-left: $space-lg;
    padding-top: $space-sm;
    padding-bottom: $space-sm;
    
    &:focus {
      outline: none;
    }
    
    &:first-child {
      border-top: 1px solid $grey-1;
    }
  }
  
  .accordion__button[aria-expanded='true'] {
    color: $color-brand-01;
  }

  .numberCircle {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding: 2px;
    border: 1px solid $color-silver;
    text-align: center;
    margin-right: $space-lg;

    &.active {
      border: 1px solid $color-brand-01;
      background: $color-brand-01;
      color: $grey-8;
    }

    &.done {
      color: $grey-8;
      background: $color-support-green;
      border: 1px solid $color-support-green;
    }
  }
  
  &:hover {
    cursor: pointer;
    background: $grey-1;
  }
}

.accordion__panel {
  border: 1px solid $grey-1;
  border-top: none;
  
}
﻿.cardiology-report-form {
  width: 100%;

  .report-form {
    display: flex;
    flex-direction: column;

    h4 {
      padding-left: 2rem;
      padding-top: 2rem;
    }
    .finding-section {
      display: flex;
      flex-direction: column;
      padding-left: 4rem;

      .input-group {
        display: flex;
        flex-direction: row;
      }

      input {
        background-color: whitesmoke;
      }
      input:after {
        background-color: red;
      }

      .text {
        color: cyan;
        margin-left: $space-md;
        order: 3;
      }

      label {
        order: 2;
        width: 250px;
      }
    }

    .radio-test {
      padding-bottom: 1rem;
    }

    .comment-section {
      display: flex;
      flex-direction: column;

      textarea {
        background: $color-primary;
        resize: y;
        width: 70%;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        margin-left: 2rem;
        margin-right: 2rem;
        padding: $space-sm;
      }
    }
  }
}

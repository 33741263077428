.report-container {
	display: flex;
	// navbar + next bar height
	height: calc(100vh - 150px);

	.report-sidebar {
		height: 100%;
		flex: 0 1 350px;
		flex-direction: row;
		background: $color-primary;
		overflow-y: auto;

		@media screen and (max-width: map-get($map: $breakpoints, $key: md)) {
					flex: 0 1 300px;
				}

		.personal-identifier {
			display: flex;
			flex-direction: row;

			h4 {
				padding-left: $space-lg;
				padding-top: $space-lg;
				padding-bottom: $space-xl;
				flex: 1;
				margin-bottom: 0;
			}

			span {
				margin-top: auto;
				margin-bottom: auto;
				max-height: 30px;
				flex: 1;
				margin-right: $space-lg;
			}
		}

		.sidebar-button {
			display: flex;
			flex-direction: row;
			color: $color-silver;
			background: $grey-8;
			border-bottom: 1px solid $grey-1;
			padding-left: $space-lg;
			padding-top: $space-sm;
			padding-bottom: $space-sm;
			outline: none;

			.numberCircle {
				border-radius: 50%;
				width: 2rem;
				height: 2rem;
				padding: 2px;
				border: 1px solid $color-silver;
				text-align: center;
				margin-right: $space-lg;

				&.done {
					color: $grey-8;
					background: $color-support-green;
					border: 1px solid $color-support-green;
				}
			}

			span {
				margin-top: auto;
				margin-bottom: auto;

				&.active {
					font-weight: bold;
					color: $color-brand-01;
				}
			}
		}

		.sidebar-button:hover {
			cursor: pointer;
			background: $grey-1;
		}

		.sidebar-button-reverse {
			color: $white;
			padding-left: $space-lg;
			padding-right: $space-lg;
			padding-top: $space-sm;
			padding-bottom: $space-sm;

			&.standards {
				display: flex;
				justify-content: space-between;
				gap: $space-sm;
				flex-wrap: wrap;
			}
			
			.cardiology-restudy {
				button {
					position: relative;
					margin-top: 450%;
					right: 60%;
				}
			}
		}

		.sidebar-checkbox {
			display: flex;
			padding-left: $space-lg;
			margin-top: $space-lg;

			span {
				color: $color-silver;
				padding-right: $space-lg;
				margin-top: auto;
				margin-bottom: auto;
			}

			button {
				height: 44px;
				width: 44px;
				line-height: 16px;
				text-align: center;
				border: 1px solid $color-silver;
				border-radius: 5px;
				margin-right: $space-md;
				background: none;
				color: $color-silver;
				font-weight: bold;

				&:hover {
					color: $color-support-green;
					border: 1px solid $color-support-green;
				}

				&.active {
					color: $grey-8;
					background: $color-support-green;
					border: 1px solid $color-support-green;
				}
			}
		}
	}

	.report-form {
		color: $color-silver;

		height: 100%;
		flex: 3;
		overflow-y: scroll;

		button.btn {
			position: relative;
			display: inline-block;
			margin-top: 20px;
		}

		button.btn[data-text]:hover::after {
			position:absolute;
			display: inline-block;
			content: attr(data-text);
			bottom: 150%;
			left: 50%;
			transform: translateX(-50%);
			padding: convert-rem(6px);
			border-radius: 10px;
			background: $grey-8;
			color: $color-support-green;
			font-size: convert-rem(12px);
			width: convert-rem(160px);
			line-height: 1rem;
			white-space: pre-line;
			z-index: 1;
		}
	}
}

.report-next-bar {
	position: fixed;
	display: flex;
	height: 80px;
	max-height: 80px;
	flex: 1;
	width: 100%;
	background: $grey-8;
	bottom: 0px;

	button {
		margin: auto $space-sm;
		padding-right: $space-lg;
		padding-left: $space-lg;

		&:nth-of-type(1) {
			margin-left: $space-lg;
		}

		&:last-of-type {
			margin-right: $space-lg;
		}
	}

	.custom-spinner {
		margin-left: auto;
		margin-top: -20px;
		padding-right: 40px;
		padding-left: 40px;
	}

	.report-details-footer {
		margin-left: auto;
		margin-top: auto;
		margin-bottom: auto;
		display: flex;
	}
}

.report-next-bar-preview {
	position: fixed;
	bottom: 0;
	display: flex;
	height: 80px;
	max-height: 80px;
	flex: 1;
	width: 100%;
	background: $grey-8;

	button {
		margin: auto $space-xl auto auto;
		padding-right: $space-lg;
		padding-left: $space-lg;
	}

	.custom-spinner {
		margin: auto $space-xl auto auto;
		padding-right: $space-lg;
		padding-left: $space-lg;
		margin-top: -4px;
	}

	.report-details-footer {
		margin: auto $space-xl auto auto;
	}
}

.report-details-container {
	display: flex;
	flex-direction: row;
	margin-top: $space-xl;

	.report-details-col {
		display: flex;
		flex: 1;
		flex-direction: column;

		.report-details-pair {
			flex: 1;
			display: flex;
			flex-direction: row;
			margin-left: $space-xl;
			margin-right: $space-xl;
			margin-top: $space-md;

			.detail-label {
				flex: 1;
				padding: $space-sm;
				color: $color-silver;
			}

			.detail-content {
				white-space: pre-wrap;
				flex: 3;
				color: $color-silver;
				padding: $space-sm;
				background: $grey-8;
				border-radius: 5px;
			}
		}

		.report-details-filler {
			height: 100%;
			margin-bottom: auto;
		}
	}
}

.pdf-preview {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	padding-bottom: $space-xl;

	.react-pdf__Page {
		margin-top: $space-xl;
		margin-left: $space-xs;
		margin-right: $space-xs;
	}
}
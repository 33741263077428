
/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Buttons
https://medium.com/codyhouse/create-your-design-system-part-5-icons-594f39cfb1b
-------------------------------- */
/*
Buttons - all styles are found here, the generic button style, accent colour styles.
*/


//Variables have been set up, change sizes and radius according to your project style 

// % protected region % [Change button variables here] on begin
$btn-font-size: $base-font-size;
$radius: 4px;

$btn-xs: calc(#{$btn-font-size} - 0.4rem);
$btn-sm: calc(#{$btn-font-size} - 0.2rem);
$btn-md: calc(#{$btn-font-size} + 0.2rem);
$btn-lg: calc(#{$btn-font-size} + 0.4rem);
$btn-xlg: calc(#{$btn-font-size} + 0.6rem);

$btn-radius: $radius;
// % protected region % [Change button variables here] end

// % protected region % [Add additional button variables here] off begin
// % protected region % [Add additional button variables here] end

/********** STANDARD BUTTONS **********/
// % protected region % [Change standard button style here] off begin
a {
	&.btn {
		text-decoration: none;
	}
}

/*Universal button styles, controlling display settings, radius, width, height and other declarations. Add classes to your HTML to combine baseline styles with
/*other style modules*/

.btn {
	display: inline-flex;
	position: relative;
	border: none;
	justify-content: center;
	white-space: nowrap;
	font-weight: 500;
	text-decoration: none;
	line-height: 1;
	padding: $space-xs;
	border-radius: $btn-radius;
	font-size: $btn-font-size;
	cursor: pointer;
	transition: .2s;
	@include appearance-none;
	@include fontSmooth;

	a & {
		text-decoration: none;
		color: $black;
		border-bottom: none;

		&:active,
		&:focus,
		&:visited {
			text-decoration: none;
		}
	}

	&:focus {
		outline: none;
	}

	&:active {
		transition: none;
	}

	&:hover {
		cursor: pointer;
		@include transform-timing($animation-time);

		@-moz-document url-prefix() {
			transition: none;
		}
	}
}
// % protected region % [Change standard button style here] end

/********** STYLE BUTTONS **********/
// % protected region % [Change solid button style here] on begin
/*Solid colour background button*/
.btn--solid {
	background-color: $grey-4;
	color: $color-brand-01;

	&:hover {
		background-color: $color-brand-01;
		color: $color-primary;
		@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(3px), $color-brand-01);
		
		a {
			color:  $color-primary;
		}
	}
}
// % protected region % [Change solid button style here] end

// % protected region % [Change outline button style here] on begin
/*Button style which has no background colour, only a border. On hover, the background will fill with solid color and the text color will invert*/
.btn--outline {
	color: $color-brand-01;
	background-color: transparent;
	@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(3px), $color-brand-01);
	
	&:hover {
		background-color: $color-brand-01;
		color: $color-primary;
		@include transform-timing($animation-time);
	}

	//Altering styles for these modular classes in this instance, to prevent overrides//
	&.btn--secondary {
		color: $color-brand-02;
		@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(3px), $color-brand-02);
		background-color: transparent;

		&:hover {
			background-color: $color-secondary;
			color: $white;
		}
	}

	&.btn--primary {
		color: $color-primary;
		@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(3px), $color-primary);
		background-color: transparent;

		&:hover {
			background-color: $color-primary;
			color: $white;
		}
	}
}
// % protected region % [Change outline button style here] end

// % protected region % [Change text button style here] on begin
/*Button which is plain text only*/
.btn--text {
	border: none;
	background: transparent;

	&:hover {
		text-decoration: underline;
		@include transform-timing($animation-time);
	}

	/*Removes modular styles in this instance for primary and secondary button styles*/
	&.btn--secondary {
		color: $color-brand-01;
		background-color: transparent;
		border: none;

		&:hover {
			box-shadow: none;
		}
	}

	/*Removes modular styles in this instance for primary and secondary button styles*/
	&.btn--primary {
		color: $color-brand-01;
		background-color: transparent;
		border: none;

		&:hover {
			box-shadow: none;
		}
	}
}
// % protected region % [Change text button style here] end


/********** ACCENT BUTTONS **********/
// % protected region % [Change accent button style here] off begin
/*Sets the primary color for a button*/
.btn--primary {
	background-color: $color-primary;
}

/* Sets the secondary color for a button*/
.btn--secondary {
	background-color: $color-secondary;

	&:hover, &:active{
		background-color: transparent;
		color: $color-secondary;
		@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(3px), $color-secondary);
	}
}
// % protected region % [Change accent button style here] end


/********** FEEDBACK/UTILITY BUTTONS **********/
// % protected region % [Change warning button style here] on begin
/*Warning button*/
.btn--warning {
	&.btn--solid {
		background-color: $color-support-orange;
		color: $color-primary;

		&:hover {
			background-color: $color-support-orange-dark;
			@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(3px), $color-support-orange-dark);
		}
	}

	&.btn--outline {
		color: $color-support-orange;
		@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(3px), $color-support-orange);

		&:hover {
			background-color: $color-support-orange;
			color: $white;
		}
	}

	&.btn--text {
		color: $color-support-orange;
	}
}
// % protected region % [Change warning button style here] end

// % protected region % [Change error button style here] off begin
/*Error button*/
.btn--error {
	&.btn--solid {
		background-color: $color-support-red;
		color: $white;

		&:hover {
			background-color: $color-support-red-dark;
			color: $white;
			@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(3px), $color-support-red-dark);
		}
	}

	&.btn--outline {
		color: $color-support-red;
		@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(3px), $color-support-red);

		&:hover {
			background-color: $color-support-red;
			color: $white;
		}
	}

	&.btn--text {
		color: $color-support-red;
	}
}
// % protected region % [Change error button style here] end

// % protected region % [Change success button style here] on begin
/*Button to indicate success, next action and other CTAs*/
.btn--success {
	&.btn--solid {
		background-color: $color-support-green;
		color: $color-primary;

		&:hover {
			background-color: $color-support-green-dark;
			@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(3px), $color-support-green-dark);
		}
	}

	&.btn--outline {
		color: $color-support-green;
		@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(3px), $color-support-green);

		&:hover {
			background-color: $color-support-green;
			color: $white;
		}
	}

	&.btn--text {
		color: $color-support-green;
	}
}
// % protected region % [Change success button style here] end

// % protected region % [Change disabled button style here] off begin
/*Disabled button*/
.btn[disabled] {
	cursor: not-allowed;
	background-color: $grey-2;
	color: $black;
	box-shadow: none;
	opacity: 0.5;
	@include inner-box-shadow(convert-rem(0px), convert-rem(0px), convert-rem(0px), convert-rem(3px), $grey-2);

	&.btn--solid {
		&:hover {
			box-shadow: none;
		}
	}

	&.btn--outline {
		background-color: transparent;

		&:hover {
			background-color: transparent;
		}
	}

	&.btn--text {
		box-shadow: none;
		background-color: transparent;

		&:hover {
			background: transparent;
			text-decoration: none;
		}
	}

	&:visited,
	&:hover,
	&:active,
	&:focus {
		background-color: $grey-2;
		color: $black;
	}
}
// % protected region % [Change disabled button style here] end

/********** SIZES **********/
// % protected region % [Change button sizes here] off begin
.btn--xsm {
	font-size: $btn-xs;
}

.btn--sm {
	font-size: $btn-sm;
}

.btn--md {
	font-size: $btn-md;
}

.btn--lg {
	font-size: $btn-lg;
}

.btn--xlg {
	font-size: $btn-xlg;
}

.btn--full-width {
	display: flex;
	width: 100%;
	justify-content: center;
}

.btn--auto-width {
	display: flex;
	width: auto;
	justify-content: center;
}
// % protected region % [Change button sizes here] end


/********** GROUPED BUTTONS **********/
// % protected region % [Change button group styles here] off begin
.btn-group {
	display: flex;
	flex-wrap: wrap;

	.btn {
		margin: $space-xs;
	}

	//TO DO !!!
	&.btn-group--vertical {
		flex-direction: column;

		.btn {
			width: auto;
			margin-left: 0;
		}
	}

	&.btn-group--horizontal {
		flex-direction: row;
	}

	&.btn-group--grow-elements {
		.btn {
			flex: auto;
		}
	}

	&.btn-group--static-elements {}
}
// % protected region % [Change button group styles here] end

// % protected region % [Add additional button styles here] off begin
// % protected region % [Add additional button styles here] end
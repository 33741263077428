.custom-spinner {
    margin-left: auto;
    margin-right: auto;

    .custom-loader {
        font-size: 1rem;
        margin-top: calc(2 * 1rem);
        margin-left: auto;
        margin-right: auto;
        text-indent: -9999em;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: $color-brand-01;
        background: -moz-linear-gradient(left, $color-brand-01 10%, rgba(255, 255, 255, 0) 42%);
        background: -webkit-linear-gradient(left, $color-brand-01 10%, rgba(255, 255, 255, 0) 42%);
        background: -o-linear-gradient(left, $color-brand-01 10%, rgba(255, 255, 255, 0) 42%);
        background: -ms-linear-gradient(left, $color-brand-01 10%, rgba(255, 255, 255, 0) 42%);
        background: linear-gradient(to right, $color-brand-01 10%, rgba(255, 255, 255, 0) 42%);
        position: relative;
        -webkit-animation: load3 1.4s infinite linear;
        animation: load3 1.4s infinite linear;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
      }
      .custom-loader:before {
        width: 50%;
        height: 50%;
        background: $color-brand-01;
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
      }
      .custom-loader:after {
        background: $color-secondary;
        width: 75%;
        height: 75%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
      @-webkit-keyframes load3 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      @keyframes load3 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
}
.standards-images-page {
    img {
        width: auto;
        height: auto;
        max-height: 100vh;
        max-width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
    }
}
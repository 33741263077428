/*
 * @bot-written
 * 
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 * 
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Login
-------------------------------- */
/*
Login, register and reset password pages
*/

// % protected region % [Change login, register and reset password styles here] on begin
.login,
.reset-password,
.register {
	display: flex;
	align-items: flex-start;
	align-content: center;
	flex-direction: column;
	width: 80%;
	margin: 0 auto;
	justify-content: center;

	@include media(xs, sm, md) {
		height: auto;
		margin: $space-sm auto;
	}

	h2 {
		margin: 0 0 $space-lg 0;

		@include media(xs, sm, md) {
			margin: 0 0 $space-md 0;
		}
	}

	.input-group {
		width: 100%;
		max-width: unset;
		margin: .5rem 0;

		label {
			font-weight: $text-regular;
		}

		.icon-look {
			position: absolute;
			right: 0;
			bottom: 1.2rem;

			&:hover {
				text-decoration: none;
				color: $color-secondary;
				@include transform-timing($animation-time);
			}
			&:before {
				margin-right: 0;
			}
		}
	}

	.btn-group {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		margin-top: $space-md;

		@include media(xs, sm, md) {
			margin-top: $space-xs;
		}

		@media screen and (max-width: 620px) {
			flex-direction: column;
		}

		.btn {
			padding: $space-md;
			display: flex;
			margin: 0;
			flex-grow: 1;

			@include media(xs, sm, md) {
				padding: $space-xs;
				margin: $space-xs auto;
			}

			@media screen and (max-width: 620px) {
				width: 100%;
			}

			&.btn--secondary {
				margin-left: $space-xxxl;

				@media screen and (max-width: 620px) {
					margin-left: 0;
				}
			}
		}
	}
}

.register {
	align-items: flex-start;

	a {
		text-decoration: none;
		cursor: pointer;
		min-height: convert-rem(20px);
		margin-bottom: $space-lg;
	}

	h5 {
		margin-top: 0;
	}

	.input-group {
		width: 100%;
	}

	.btn {
		margin: $space-lg;
	}
}

.login__forgot-password {
	display: flex;

	&:hover,
	&:active {
		border-bottom: none;
	}
}

.login__inputs {
	width: 100%;
	display: flex;
	flex-direction: column;

	.btn {
		margin-top: $spacing-xl;
	}
}

.login-container {
	display: flex;
	flex-direction: row;
	height: 100vh;
	
	.login {
		flex: 1;
		width: 100%;
		margin: 0;
		padding: 0;
		
		.radar-branding {
			width: 50%;
			height: 200px;
			background: url("/images/login_branding.png") no-repeat;
			background-size: contain;
			margin-left: auto;
			margin-right: auto;
		}

		.link-forgotten-password {
			color: white;
			cursor: pointer;
		}
		
		.input-group {
			width: 50%;
			margin-left: auto;
			margin-right: auto;
		}
		
		.btn-group {
			width: 50%;
			margin-left: auto;
			margin-right: auto;
			
			button {
				max-height: 47px;
				padding: 1rem;
			}
		}
		
		h2 {
			width: 75%;
			margin-left: auto;
		}
		
		p {
			margin-top: $spacing-lg;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.login-image {
		flex: 0;
		display: none;
	}
	
	@media screen and (min-width: 768px) {
		.login-image {
			flex: 1;
			display: flex;
			background: url("/images/login_bg.png") no-repeat;
			background-size: cover;
			height: 100vh;

			h2 {
				color: white;
				margin: auto;
				width: 50%;
				text-align: center;
			}
		}
	}
}

.reset-password {
	height: 100vh;
}
// % protected region % [Change login, register and reset password styles here] end

// % protected region % [Add additional login, register and reset password styles here] off begin
// % protected region % [Add additional login, register and reset password styles here] end
.bisected {
  display: flex;
  flex-direction: row;
  height: 100%;

  &__left,
  &__right {
    width: 50%;
  }

  &__left {
    padding: $spacing-xl;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__right {
    background: url('/images/login_bg.png') no-repeat center;
    background-size: cover;
    content: "";
  }
}

.lungscreen-logo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  
  img {
    width: 400px;
  }

  h3 {
    margin: 0;
    color: $color-brand-01;
    font-weight: 600;
    font-size: 1.4rem;
  }
}

.vertical-span {
  width: 3px;
  height: 60px;
  background-color: $grey-3;
  content: "";
}

.report-type{
  display: flex;
  justify-content: center;
  align-items: center;
	max-width: 8rem;
	border-radius: 20px;
	border: 0;
	padding: 0.5rem;
	font-weight: 600;

	&.ilo{
		background-color: $color-support-blue;
	}


	&.icoerd{
		background-color: $color-support-purple;
	}

  &.cardiology{
		background-color: $color-support-red;
	}

	&.urgent{
		background-color: $color-support-red;
		@include lightning-icon(before, warning);
	}
}